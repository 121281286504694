import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";

import PopupBatchUpdate from "../../containers/popup/PopupBatchUpdate";
import PopupBatchReport from "../../containers/popup/PopupBatchReport";
import PopupGetAPI from "../../containers/popup/PopupGetAPI";

import { getExamCampaigns, updateExamStatus } from "redux/actions/report";
import PopupSelectReportType from "containers/popup/PopupSelectReportType";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  firstName: "",
  lastName: "",
  compatibilityLess: "",
  compatibilityMore: "",
  examResultLess: "",
  examResultMore: "",
  status: "",
  disc: "",
  campaignType: "",
  startDate: "",
  endDate: "",
};

const statusList = [
  { text: "Done", value: "done" },
  { text: "Expired", value: "expired" },
  { text: "Doing", value: "doing" },
];
const statusDISCList = [{ text: "ผล DISC", value: "" }];
const campaignTypeList = [{ text: "ประเภท Campaign", value: "" }];

const ReportAll = () => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.report);

  const [reportList, setReportList] = useState(items);
  // const [campaign, setCampaign] = useState(campaignData);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const [uid, setUId] = useState();
  const [userId, setUserId] = useState();

  const [isOpenPopupBatchUpdate, setIsOpenPopupBatchUpdate] = useState(false);
  const [isOpenPopupBatchReport, setIsOpenPopupBatchReport] = useState(false);
  const [isOpenPopupGetAPI, setIsOpenPopupGetAPI] = useState(false);
  const [isOpenPopupReportGenerate, setIsOpenPopupReportGenerate] =
    useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    let index = 1;
    let newItems = items.map((it) => {
      return { ...it, id: index++ };
    });
    setReportList(newItems);
  }, [items]);

  const handleSearch = () => {
    setPage(0);
    dispatch(getExamCampaigns(filter));
  };

  useEffect(() => {}, []);

  const GetAPIButton = ({ value }) => {
    return <Button onClick={() => handleGetAPI(value)}>Button Get API</Button>;
  };

  const GenerateReportButton = ({ row }) => {
    if (row?.status === "done")
      return (
        <Button onClick={() => handleReportGenerate(row)}>
          Button Generate Report
        </Button>
      );
    return "-";
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
      width: 250,
    },
    {
      field: "userUid",
      headerName: "User Id",
      width: 250,
    },
    {
      field: "campaignUid",
      headerName: "Campaign Id",
      width: 250,
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      width: 300,
    },
    { field: "firstName", headerName: "ชื่อ", width: 250 },
    { field: "lastName", headerName: "นามสกุล", width: 250 },
    { field: "userExam", headerName: "จำนวนวิชาที่สอบ", width: 250 },
    {
      field: "totalExam",
      headerName: "ข้อสอบทั้งหมด",
      width: 250,
    },
    {
      field: "campaignType",
      headerName: "ประเภท Campaign",
      width: 250,
    },
    { field: "dateExam", headerName: "วันที่เข้าทำแบบทดสอบ", width: 250 },
    { field: "timeExam", headerName: "เวลาที่ใช้ในการสอบ (นาที)", width: 250 },
    {
      field: "totalQuestionExam",
      headerName: "จำนวนข้อสอบทั้งหมด",
      width: 250,
    },
    {
      field: "resultExam",
      headerName: "คะแนนความรู้ (เต็ม 100%)",
      width: 250,
    },
    {
      field: "resultDisc",
      headerName: "ผล DISC",
      width: 250,
    },
    {
      field: "compatibility",
      headerName: "ความเข้ากันได้",
      renderCell: IsNullValue,
      width: 250,
    },
    {
      field: "pointPercentile",
      headerName: "ตำแหน่ง Percentile",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: IsNullValue,
      width: 250,
    },
    {
      field: "examUserStatusId",
      headerName: "Get API",
      width: 250,
      renderCell: GetAPIButton,
    },
    {
      field: "-",
      headerName: "Report",
      width: 250,
      renderCell: GenerateReportButton,
    },
  ];

  const handleBatchUpdate = () => {
    setIsOpenPopupBatchUpdate(true);
  };

  const handleCloseBatchUpdate = () => {
    setIsOpenPopupBatchUpdate(false);
    handleSearch();
  };

  const handleBatchReport = () => {
    setIsOpenPopupBatchReport(true);
  };

  const handleCloseBatchReport = () => {
    setIsOpenPopupBatchReport(false);
  };

  const handleGetAPI = (value) => {
    dispatch(updateExamStatus(value)).then((data) => {
      setIsOpenPopupGetAPI(true);
      handleSearch();
    });
  };

  const handleCloseGetAPI = () => {
    setIsOpenPopupGetAPI(false);
  };

  const handleReportGenerate = (row) => {
    setUId(row?.campaignUid);
    setUserId(row?.userUid);
    setIsOpenPopupReportGenerate(true);
  };

  const handleCloseReportGenerate = () => {
    setUId(null);
    setUserId(null);
    setIsOpenPopupReportGenerate(false);
  };

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getExamCampaigns(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getExamCampaigns(filter, nextPage * limit));
  };

  return (
    <SidebarLayout title="Report Management">
      {Boolean(isOpenPopupBatchUpdate) && (
        <PopupBatchUpdate open onClose={handleCloseBatchUpdate} />
      )}
      {Boolean(isOpenPopupBatchReport) && (
        <PopupBatchReport open onClose={handleCloseBatchReport} />
      )}
      {Boolean(isOpenPopupGetAPI) && (
        <PopupGetAPI open onClose={handleCloseGetAPI} />
      )}
      {Boolean(isOpenPopupReportGenerate) && (
        // <PopupReportGenerate
        //   open
        //   onClose={handleCloseReportGenerate}
        //   uid={uid}
        //   userId={userId}
        // />
        <PopupSelectReportType
          open
          onClose={handleCloseReportGenerate}
          uid={uid}
          userId={userId}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/report/all", text: "Report" },
            { path: "/campaign/all", text: "Campaign" },
            { path: "/configuration-report/all", text: "Configuration Report" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="ชื่อ"
                  value={filter.firstName}
                  name="search_firstname"
                  onChange={(e) =>
                    handleFilterChange("firstName", e.target.value)
                  }
                />
                <TextField
                  label="นามสกุล"
                  value={filter.lastName}
                  name="search_lastname"
                  onChange={(e) =>
                    handleFilterChange("lastName", e.target.value)
                  }
                />
                <TextField
                  label="ความเข้ากันได้ต่ำสุด"
                  value={filter.compatibilityLess}
                  name="search_compatibilityLess"
                  onChange={(e) =>
                    handleFilterChange("compatibilityLess", e.target.value)
                  }
                />
                <TextField
                  label="ความเข้ากันได้สูงสุด"
                  value={filter.compatibilityMore}
                  name="search_compatibilityMore"
                  onChange={(e) =>
                    handleFilterChange("compatibilityMore", e.target.value)
                  }
                />
                <TextField
                  label="% คะแนนความรู้ต่ำสุด"
                  value={filter.examResultLess}
                  name="search_examResultLess"
                  onChange={(e) =>
                    handleFilterChange("examResultLess", e.target.value)
                  }
                />
                <TextField
                  label="% คะแนนความรู้สูงสุด"
                  value={filter.examResultMore}
                  name="search_examResultMore"
                  onChange={(e) =>
                    handleFilterChange("examResultMore", e.target.value)
                  }
                />
              </div>
              <div className="group-field">
                <Select
                  items={statusList}
                  value={filter.status}
                  defaultValue="Status"
                  className="field-status"
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                />
                <Select
                  items={statusDISCList}
                  value={filter.disc}
                  defaultValue="ผล DISC"
                  className="field-disc"
                  onChange={(e) => handleFilterChange("disc", e.target.value)}
                />
                <Select
                  items={campaignTypeList}
                  value={filter.campaignType}
                  className="field-campaign"
                  onChange={(e) =>
                    handleFilterChange("campaignType", e.target.value)
                  }
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={handleBatchUpdate}>Batch Update</Button>
                  <Button onClick={handleBatchReport}>Batch Report</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={reportList}
              rowCount={total}
              pageSize={+limit}
              page={page}
              disableSelectionOnClick={true}
              checkboxSelection={false}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ReportAll;
