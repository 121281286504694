import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import Date from "components/form/Date";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import FileCell from "components/table/FileCell";
import StatusCell from "components/table/StatusCell";
import CheckCell from "components/table/CheckCell";

import { useHistory } from "react-router-dom";

import {} from "redux/selectors";
import {
  getQuotations,
  getInvoices,
  getQuotationById,
} from "redux/actions/order";
import PopupUploadPayment from "containers/popup/PopupUploadPayment";
import PopupProductList from "containers/popup/PopupProductList";
import PopupDocumentInvoicePDF from "containers/popup/PopupDocumentInvoicePDF";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  quotationNo: "",
};

const OrderTransaction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, total, limit } = useSelector((state) => state.order);

  const [quotationList, setQuotationList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const [quotationNo, setQuotationNo] = useState();
  const [isOpenUploadPaymentModal, setIsOpenUploadPaymentModal] =
    useState(false);
  const [isOpenViewProductListModal, setIsOpenViewProductListModal] =
    useState(false);
  const [isOpenSeeDocInvoice, setIsOpenSeeDocInvoice] = useState(false);
  const [isOpenSeeDocReceipt, setIsOpenSeeDocReceipt] = useState(false);
  const [transactionType, setTransactionType] = useState({ type: "Quotation" });
  const [invoiceId, setInvoiceId] = useState();

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setQuotationList(items);
  }, [items]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const IsConfirmPayment = ({ row }) => {
    let invoiceStatus = "";
    let invoiceStatusId = "";
    if (row?.slipImageUrl === null || !row?.slipImageUrl) return "-";
    if (row?.invoiceStatus === 2) {
      invoiceStatus = "ยืนยันการโอนเงินแล้ว";
      invoiceStatusId = "1";
    }
    return <StatusCell type={invoiceStatusId}>{invoiceStatus}</StatusCell>;
  };

  const GenerateInvoice = ({ value, row }) => {
    return (
      <Button
        onClick={() =>
          history.push(`/order/transaction/generate-invoice/${row?.id}`)
        }
      >
        Create Invoice
      </Button>
    );
  };

  const GenerateReceipt = ({ value, row }) => {
    return (
      <Button
        onClick={() =>
          history.push(`/order/transaction/generate-receipt/${row?.id}`)
        }
      >
        Create Receipt
      </Button>
    );
  };

  const UploadPayment = ({ value, row }) => {
    if (value === null || !value) {
      return (
        <Button
          onClick={() => {
            handleOpenPopupUploadPayment(row?.id);
          }}
        >
          Upload Payment Image
        </Button>
      );
    }
    return <FileCell fileName={"หลักฐานการโอนเงิน"} filePath={value} />;
  };

  const GenerateDeliveryNote = ({ value, row }) => {
    console.log(row?.invoiceStatus);
    if (row?.invoiceStatus === 2)
      return (
        <Button
          onClick={() =>
            history.push(`/order/transaction/generate-delivery-note/${row?.id}`)
          }
        >
          สร้าง Delivery Note
        </Button>
      );
    return "-";
  };

  const ViewProductList = ({ row }) => {
    return (
      <Button
        onClick={(e) => {
          handldOpenPopupViewProductList(row?.id);
        }}
      >
        ดูรายการสินค้า
      </Button>
    );
  };

  const SeeDocQuotationCell = ({ row }) => {
    return (
      <Link
        to="#"
        onClick={() => window.open(`/quotation/${row?.id}`, "_blank")}
      >
        เรียกดู quotation
      </Link>
    );
  };

  const SeeDocInvoiceCell = ({ row }) => {
    return (
      <Link to="#" onClick={() => handleOpenPopupInvoiceDoc(row?.id)}>
        เรียกดู invoice
      </Link>
    );
  };

  const SeeDocReceiptCell = () => {
    return (
      <Link to="#" onClick={() => setIsOpenSeeDocReceipt(true)}>
        เรียกดู Receipt
      </Link>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      width: 80,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
    },
    { field: "quotationNo", headerName: "Quotation Number", width: 200 },
    {
      field: "-",
      headerName: "รายการสินค้า",
      renderCell: ViewProductList,
      width: 200,
    },
    {
      field: "totalPrice",
      headerName: "Total",
      renderCell: IsNullValue,
      width: 100,
    },
    {
      field: "quotationDoc",
      headerName: "Document Quotation",
      renderCell: SeeDocQuotationCell,
      width: 200,
    },
    {
      field: "invoiceDoc",
      headerName: "Document Invoice",
      renderCell: SeeDocInvoiceCell,
      width: 200,
    },
    {
      field: "receiptDoc",
      headerName: "Document Receipt",
      renderCell: SeeDocReceiptCell,
      width: 200,
    },
    {
      field: "generateInvoice",
      headerName: "Invoice",
      renderCell: GenerateInvoice,
      width: 200,
    },
    {
      field: "generateReceipt",
      headerName: "Receipt",
      renderCell: GenerateReceipt,
      width: 200,
    },
    {
      field: "projectNo",
      headerName: "Project Number",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "programNo",
      headerName: "Program Number",
      renderCell: IsNullValue,
      width: 200,
    },
  ];

  const [columnTable, setColumnTable] = useState(columns);

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    fetchDataByTransactionType();
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
    fetchDataByTransactionType();
  };

  const fetchDataByTransactionType = () => {
    if (transactionType.type === "Quotation") {
      setColumnTable(columns);
      dispatch(getQuotations(filter));
    } else if (transactionType.type === "Invoice") {
      setColumnInvoice();
      dispatch(getInvoices(filter));
    }
  };

  const setColumnInvoice = () => {
    setColumnTable([
      {
        field: "id",
        headerName: "No",
        width: 80,
      },
      {
        field: "createdAt",
        headerName: "Date",
        width: 200,
      },
      {
        field: "invoiceNo",
        headerName: "Invoice Number",
        renderCell: IsNullValue,
        width: 200,
      },
      {
        field: "quotationNo",
        headerName: "Quotation Number",
        renderCell: IsNullValue,
        width: 200,
      },
      {
        field: "price",
        headerName: "Amount",
        renderCell: IsNullValue,
        width: 200,
      },
      {
        field: "paymentTerm",
        headerName: "Payment Term",
        renderCell: IsNullValue,
        width: 200,
      },
      {
        field: "totalTax",
        headerName: "Tax",
        width: 100,
      },
      {
        field: "totalNet",
        headerName: "Vat",
        renderCell: IsNullValue,
        width: 200,
      },
      {
        field: "totalPrice",
        headerName: "Total",
        renderCell: IsNullValue,
        width: 200,
      },
      {
        field: "slipImageUrl",
        headerName: "หลักฐานการโอนเงิน",
        renderCell: UploadPayment,
        width: 200,
      },
      {
        field: "-",
        headerName: "Action",
        renderCell: IsConfirmPayment,
        width: 300,
      },
      {
        field: "",
        headerName: "Delivery Note",
        renderCell: GenerateDeliveryNote,
        width: 200,
      },
    ]);
  };

  const handleChangeType = (key, value) => {
    setTransactionType({
      ...transactionType,
      [key]: value,
    });
  };
  const handleOpenPopupUploadPayment = (id) => {
    setIsOpenUploadPaymentModal(true);
    setInvoiceId(id);
  };

  const handleClosePopupUploadPayment = () => {
    setIsOpenUploadPaymentModal(false);
    setTimeout(() => {
      handleSearch();
    }, 500);
  };

  const handldOpenPopupViewProductList = (id) => {
    setIsOpenViewProductListModal(true);
    dispatch(getQuotationById(id));
  };

  const handldClosePopupViewProductList = () => {
    setIsOpenViewProductListModal(false);
  };

  const handleOpenPopupInvoiceDoc = (id) => {
    dispatch(getQuotationById(id));
    setIsOpenSeeDocInvoice(true);
  };

  const handleClosePopupInvoiceDoc = () => {
    setIsOpenSeeDocInvoice(false);
  };

  return (
    <SidebarLayout title="Order Management">
      {Boolean(isOpenUploadPaymentModal) && (
        <PopupUploadPayment
          open
          onClose={handleClosePopupUploadPayment}
          invoiceId={invoiceId}
        />
      )}
      {Boolean(isOpenViewProductListModal) && (
        <PopupProductList open onClose={handldClosePopupViewProductList} />
      )}
      {Boolean(isOpenSeeDocInvoice) && (
        <PopupDocumentInvoicePDF
          open
          label={"Invoice Table List"}
          maxWidth={"lg"}
          onClose={handleClosePopupInvoiceDoc}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/order/transaction", text: "Transaction" },
            { path: "/order/regconsign", text: "Regconsign" },
            { path: "/order/summary", text: "SummaryReport" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="Quotation Number"
                  value={filter.quotationNo}
                  onChange={(e) =>
                    handleFilterChange("quotationNo", e.target.value)
                  }
                  name="search_quotationNo"
                />
              </div>
            </div>

            <div className="section-group">
              <div className="group-field">
                <Button
                  outlined={transactionType.type !== "Quotation"}
                  onClick={(e) => handleChangeType("type", "Quotation")}
                >
                  Quotation
                </Button>
                <Button
                  onClick={(e) => handleChangeType("type", "Invoice")}
                  outlined={transactionType.type !== "Invoice"}
                >
                  Invoice
                </Button>
              </div>
              <br />
              <div className="group-field">
                <Button>ทั้งหมด</Button>
                <Button>งานประมูล</Button>
                <Button>สมาชิก</Button>
                <Button>งานขนส่ง</Button>
                <Button>งานอบรม</Button>
                <Button>งานอื่นๆ</Button>
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button
                    onClick={() =>
                      history.push("/order/transaction/create-quotation")
                    }
                  >
                    Create quotation
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columnTable}
              rows={quotationList}
              checkboxSelection={false}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              // onSelectionChange={(id) => setSelectedId(id)}
              // onEditRow={handleEditquotation}
              // onDeleteRow={handleEditquotation}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default OrderTransaction;
