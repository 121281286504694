import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import StatusCell from "components/table/StatusCell";
import PopupAddNewSagment from "containers/popup/PopupAddNewSagment";
import PopupConfirmDeleteSagment from "containers/popup/PopupConfirmDeleteSagment";

import {} from "redux/selectors";
import { getSegment } from "redux/actions/member";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;


const initFilter = {
  idCard: "",
  firstName: "",
  lastName: "",
  email: "",
  type: 1,
};

const MemberSagment = () => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.member);

  const [segmentList, setSegmentList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [selectedId, setSelectedId] = useState([]);

  const [isOpenNewSegmentModal, setIsOpenNewSegmentModal] = useState(false);
  const [isOpenconfirmDeleteSegmentModal, setIsOpenconfirmDeleteSegmentModal] =
    useState(false);
  const [isEditSegment, setIsEditSegment] = useState(false);
  const [isDeleteSegment, setIsDeleteSegment] = useState(false);

  const [dataRowEdit, setDataRowEdit] = useState({});
  const [dataRowDelete, setDataRowDelete] = useState({});

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setSegmentList(items);
  }, [items]);

  const LinkDetailCell = ({ value }) => {
    return (
      <RouterLinkCell to={`/member/segment/detail/${value}`}>
        {value}
      </RouterLinkCell>
    );
  };

  const StatusCellTag = ({ value }) => {
    let type = "";
    if (value === "1") {
      type = "TLT";
    } else if (value === "2") {
      type = "CMS";
    }
    return <StatusCell type={type}>{type}</StatusCell>;
  };

  const columns = [
    { field: "id", headerName: "Id", renderCell: LinkDetailCell },
    { field: "nameTh", headerName: "Segment" },
    { field: "tag", headerName: "แท็ก", renderCell: StatusCellTag },
    { field: "memberCount", headerName: "จำนวน" },
    { field: "createdAt", headerName: "Date Create" },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getSegment(initFilter));   
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getSegment(filter));
  };

  const handleNewSagment = () => {
    setIsOpenNewSegmentModal(true);
  };

  const handleEditSegment = (row) => {
    setIsOpenNewSegmentModal(true);
    setIsEditSegment(true);
    setDataRowEdit(row);
  };

  const handleCloseNewSagmentPopup = () => {
    setIsOpenNewSegmentModal(false);
    setIsEditSegment(false);
    setDataRowEdit();
    handleSearch();
  };

  const handleDeleteItem = ({ id }) => {
    setIsOpenconfirmDeleteSegmentModal(true);
    setIsDeleteSegment(true);
    setDataRowDelete({ id: id });
  };

  const handleCloseDeleteItemPopup = () => {
    setIsOpenconfirmDeleteSegmentModal(false);
    setIsDeleteSegment(false);
    setDataRowDelete();
    handleSearch();
  };

  return (
    <SidebarLayout title="Member Management">
      {Boolean(isOpenNewSegmentModal) && (
        <PopupAddNewSagment
          open
          isEdit={isEditSegment}
          dataEdit={dataRowEdit}
          onClose={handleCloseNewSagmentPopup}
        />
      )}
      {Boolean(isOpenconfirmDeleteSegmentModal) && (
        <PopupConfirmDeleteSagment
          open
          isDelete={isDeleteSegment}
          dataDelete={dataRowDelete}
          onClose={handleCloseDeleteItemPopup}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/member/all", text: "All" },
            { path: "/member/segment", text: "Segment" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="ชื่อ"
                  value={filter.firstName}
                  onChange={(e) =>
                    handleFilterChange("firstName", e.target.value)
                  }
                  name="search_firstname"
                />
                <TextField
                  label="Tag"
                  value={filter.lastName}
                  onChange={(e) =>
                    handleFilterChange("lastName", e.target.value)
                  }
                  name="search_lastname"
                />
              </div>
            </div>
            {/* <div className="section-group">
              <div className="group-title">User type</div>
              <br />
              <div className="group-field">
                <Button
                  outlined={filter.type !== 1}
                  onClick={(e) => handleFilterChange("type", 1)}
                >
                  Individual
                </Button>
                <Button
                  onClick={(e) => handleFilterChange("type", 2)}
                  outlined={filter.type !== 2}
                >
                  Business
                </Button>
              </div>
            </div> */}
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={handleNewSagment}>New Sagment</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={segmentList}
              checkboxSelection={false}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              onSelectionChange={(id) => setSelectedId(id)}
              onEditRow={handleEditSegment}
              onDeleteRow={handleDeleteItem}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default MemberSagment;
