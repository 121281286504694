import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import TextField from "components/form/TextField";
import TextFieldArea from "components/form/TextFieldArea";
import Select from "components/form/Select";
import ButtonIconDelete from "components/form/ButtonIconDelete";
import Button from "components/form/Button";
import { Grid } from "@material-ui/core";

import { getDiscResults } from "redux/actions/report";

import { SUBJECT_DETAIL_CONFIG_REPORT } from "utils/dataConfig";
const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0;

  .group-title {
    font-size: 20px;
    font-weight: bold;
  }
`;

const subjectList = [
  { value: 1, text: "Math" },
  { value: 2, text: "Logic" },
  { value: 3, text: "English" },
  { value: 4, text: "DISC" },
];

const SubjectPanel = ({
  formSubjectProp,
  formDISCProp,
  onChangeDISCProp,
  onChangeResultProp,
  indexOfForm = 0,
  onChangeProp,
  setDataConfigSubject,
  handleDelete,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { discs } = useSelector((state) => state.report);
  const [discList, setDiscList] = useState(
    discs.map((it) => {
      return { value: it.id, text: it.id };
    })
  );


  useEffect(() => {
    dispatch(getDiscResults());
  }, []);

  useEffect(() => {
    switch (formSubjectProp?.subject_id) {
      case "1":
        setDataConfigSubject(indexOfForm, SUBJECT_DETAIL_CONFIG_REPORT?.math);
        break;
      case "2":
        setDataConfigSubject(indexOfForm, SUBJECT_DETAIL_CONFIG_REPORT?.logic);
        break;
      case "3":
        setDataConfigSubject(
          indexOfForm,
          SUBJECT_DETAIL_CONFIG_REPORT?.english
        );
        break;
      case "4":
        setDataConfigSubject(indexOfForm, SUBJECT_DETAIL_CONFIG_REPORT?.disc);
        break;

      default:
        break;
    }
  }, [formSubjectProp?.subject_id]);

  useEffect(() => {
    setDiscList(
      discs.map((it) => {
        return { value: it.id, text: it.id };
      })
    );
  }, [discs]);

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Select
            name={"subject_id"}
            label={<div className="group-title">วิชา</div>}
            items={subjectList}
            defaultValue={"เลือกวิชา"}
            value={formSubjectProp?.subject_id}
            onChange={(e) => onChangeProp(e, indexOfForm)}
          />
        </Grid>
        <Grid item container alignItems="flex-end" xs={3}>
          {formSubjectProp?.subject_id.toString() === "4" ? (
            <Button onClick={() => history.push("/configuration-report/disc")}>
              View DISC Configuration
            </Button>
          ) : (
            <TextField
              name={"weight"}
              type={"number"}
              label={<div className="group-title">สัดส่วน</div>}
              value={formSubjectProp?.weight}
              onChange={(e) => onChangeProp(e, indexOfForm)}
            />
          )}
        </Grid>
        {indexOfForm !== 0 && (
          <Grid container item xs={1}>
            <ButtonIconDelete onClick={() => handleDelete(indexOfForm)} />
          </Grid>
        )}
        <Grid item xs={12}>
          <div className="group-title">คุณสมบัติรายวิชา</div>
        </Grid>

        <Grid container spacing={1}>
          {formSubjectProp?.report_subject_result_mappings?.map((item, index) => (
            <>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  value={item?.name}
                  onChange={(e) => onChangeResultProp(e, indexOfForm, index)}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Grid spacing={1} container direction="row" alignItems="center">
                  <Grid item xs={5}>
                    <TextField
                      disabled
                      name={"min_score"}
                      value={item?.min_score}
                      onChange={(e) =>
                        onChangeResultProp(e, indexOfForm, index)
                      }
                    />
                  </Grid>
                  <Grid container item justifyContent="center" xs={2}>
                    To
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      disabled
                      name={"max_score"}
                      value={item?.max_score}
                      onChange={(e) =>
                        onChangeResultProp(e, indexOfForm, index)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <TextFieldArea
                  name={"description"}
                  onChange={(e) => onChangeResultProp(e, indexOfForm, index)}
                  value={item?.description}
                  disabled
                />
              </Grid>
            </>
          ))}
        </Grid>

        {formSubjectProp?.subject_id.toString() === "4" && (
          <>
            <Grid item xs={12}>
              <div className="group-title">คุณสมบัติรายวิชา</div>
            </Grid>
            <Grid item xs={1}>
              <TextField disabled value={"DISC"} />
            </Grid>
            <Grid item xs={1}>
              <Select
                name={"disc_target"}
                items={discList}
                defaultValue={"เลือกวิชา"}
                value={formDISCProp?.disc_target || formSubjectProp?.disc_target}
                onChange={(e) => onChangeDISCProp(e)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Wrapper>
  );
};

export default SubjectPanel;
