import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";

import Grid from "@mui/material/Grid";

import Table from "components/table/Table";
import RouterLinkNewTabCell from "components/table/RouterLinkNewTabCell";

const Div = styled.div`
  padding: 20px;
  width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }
`;

const PopupDocumentInvoicePDF = ({ open, onClose, label, maxWidth }) => {
  const dispatch = useDispatch();
  const { quotationById } = useSelector((state) => state.order);
  const [productList, setProductList] = useState([]);

  const IsNullValue = ({ value }) => {
    if (value == null || !value) return "-";
    return value;
  };

  const ProveOfPaymentCell = ({ value }) => {
    return <RouterLinkNewTabCell to={value}>ภาพประกอบ</RouterLinkNewTabCell>;
  };

  const SeeInvoiceCell = ({ row }) => {
    return (
      <Link to="#" onClick={() => window.open(`/invoice/${row?.id}`, "_blank")}>
        เรียกดู invoice
      </Link>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
    },
    {
      field: "createdAt",
      headerName: "Date",
    },
    {
      field: "invoice_no",
      headerName: "Invoice Number",
    },

    {
      field: "payment_term",
      headerName: "Payment Term",
    },
    {
      field: "viewPdf",
      headerName: "เรียกดู invoice",
      renderCell: SeeInvoiceCell,
    },
  ];

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">{label}</div>
          </Grid>
        </Grid>

        <div className="table-wrapper">
          <Table
            columns={columns}
            rows={quotationById?.invoice}
            checkboxSelection={false}
          />
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Close{" "}
          </Button>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupDocumentInvoicePDF;
