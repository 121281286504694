import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import Button from "components/form/Button";

import Grid from "@mui/material/Grid";

import Table from "components/table/Table";
import StatusCell from "components/table/StatusCell";

import { getMember, addUserSegment } from "redux/actions/member";

const initFilter = {
  idCard: "",
  firstName: "",
  lastName: "",
  email: "",
  type: 1,
  segment: null,
};

const Div = styled.div`
  padding: 20px;
  width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    padding-left: 250px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }
`;

const PopupAddUserSagment = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth,
  error,
  segmentId,
}) => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.member);
  const [memberList, setMemberList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [segmentName, setSegmentName] = useState("");
  const [tag, setTag] = useState("");
  const [selectedId, setSelectedId] = useState([]);

  const handleTagChange = (value) => {
    setTag(value);
  };

  const StatusCellWrapper = ({ value }) => {
    let type = "";
    if (value === 1) {
      type = "Individual";
    } else if (value === 2) {
      type = "Business";
    }
    return <StatusCell type={type}>{type}</StatusCell>;
  };

  const IsNullValue = ({ value }) => {
    if (value == null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "idCard",
      headerName: "Id Card Number",
    },
    { field: "firstName", headerName: "Name" },
    { field: "lastName", headerName: "Surname" },
    { field: "nationality", headerName: "Nationality" },
    { field: "phone", headerName: "Phone", renderCell: IsNullValue },
    { field: "email", headerName: "Email", renderCell: IsNullValue },
    {
      field: "memberType",
      headerName: "Member Type",
      renderCell: StatusCellWrapper,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSetSegmentId = () => {
    initFilter.segment = +segmentId;
    setFilter(initFilter);
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getMember(filter));
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getMember(initFilter));
  };

  const submitAddUser = () => {
    let obj = {
      flag: "add",
      member: selectedId.map((it) => {
        return { member_id: it, segment_id: +segmentId };
      }),
    };
    dispatch(addUserSegment(obj));
    onClose();
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setMemberList(items);
  }, [items]);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Add User To Segment</div>
          </Grid>
          <Grid item xs={2}>
            <Button
              className="button-invite"
              type="submit"
              onClick={(e) => submitAddUser()}
            >
              Invite
            </Button>
          </Grid>
        </Grid>

        <div className="section-group">
          <div className="group-title">Member Information</div>
          <div className="group-field">
            <TextField
              label="ชื่อ"
              value={filter.firstName}
              onChange={(e) => handleFilterChange("firstName", e.target.value)}
              name="search_firstname"
            />
            <TextField
              label="นามสกุล"
              value={filter.lastName}
              onChange={(e) => handleFilterChange("lastName", e.target.value)}
              name="search_lastname"
            />
            <TextField
              label="อีเมล์"
              value={filter.email}
              onChange={(e) => handleFilterChange("email", e.target.value)}
              name="search_email"
            />
          </div>
        </div>
        <div className="section-group">
          <div className="group-title">User type</div>
          <br />
          <div className="group-field">
            <Button
              outlined={filter.type !== 1}
              onClick={(e) => handleFilterChange("type", 1)}
            >
              Individual
            </Button>
            <Button
              onClick={(e) => handleFilterChange("type", 2)}
              outlined={filter.type !== 2}
            >
              Business
            </Button>
          </div>
        </div>
        <div className="section-group ">
          <div className="group-field end">
            <Button onClick={() => handleSearch()}>Search</Button>
            <Button onClick={() => handleClearFilter()} outlined>
              Clear
            </Button>
          </div>
        </div>
        <div className="table-wrapper">
          <Table
            columns={columns}
            rows={memberList}
            checkboxSelection={true}
            rowCount={total}
            pageSize={limit}
            page={page}
            onPageChange={handlePageChange}
            onSelectionChange={(id) => setSelectedId(id)}
          />
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          {/* <ButtonBlack type="submit" onClick={() => submitAddSegment()}>
            Add
          </ButtonBlack> */}
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupAddUserSagment;
