import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import Date from "components/form/Date";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import FileCell from "components/table/FileCell";
import StatusCell from "components/table/StatusCell";
import CheckCell from "components/table/CheckCell";
import PopupAddUserSagment from "containers/popup/PopupAddUserSagment";
import PopupConfirmDeleteUserSagment from "containers/popup/PopupConfirmDeleteUserSagment";
import PopupRequestApi from "containers/popup/PopupRequestApi";

import { Grid, Tabs, Tab, Box } from "@material-ui/core";
import InlineSpaceText from "components/form/InlineSpaceText";
import Paper from "components/surfaces/Paper";
import Divider from "components/surfaces/Divider";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import TabPanel from "components/surfaces/TabPanel";
import TabDNAStatus from "./TabDNAStatus";

import {} from "redux/selectors";
import { getSegment, getSegmentById, getMember } from "redux/actions/member";

const Div = styled.div`
  padding: 20px;
  .headbar-panel-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .segment-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .headline {
      display: flex;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 10px;
      height: 35px;

      .back-icon {
        font-size: 35px;
        :hover {
          cursor: pointer;
        }
      }
      .text-topic {
        margin: 2px 0 0 8px;
      }
    }
    .content-wrapper {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      row-gap: 1rem;
      margin-top: 5px;
    }
  }
  .table-wrapper {
    .table-header {
      margin-bottom: 26px;

      .label {
        font-weight: bold;
        width: 200px;
      }

      button {
        margin-top: 20px;
      }
    }
  }
  .issue-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 38px;
    border-radius: 4px;
    background: #e44c5c;
    color: #fff;
  }
  .date-issue-wrapper {
    color: #e44c5c;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }
`;

const MemberSegmentDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { items, total, limit, segmentData } = useSelector(
    (state) => state.member
  );

  const initFilter = {
    idCard: "",
    firstName: "",
    lastName: "",
    email: "",
    type: 1,
    segment: id,
  };

  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [memberList, setMemberList] = useState(items);
  const [segmentId, setSegmentId] = useState(id);
  const [isOpenAddUserSegment, setIsOpenAddUserSegmentModal] = useState(false);
  const [
    isOpenconfirmDeleteUserSegmentModal,
    setIsOpenconfirmDeleteUserSegmentModal,
  ] = useState(false);
  const [isDeleteUserSegment, setIsDeleteUserSegment] = useState(false);
  const [dataRowDelete, setDataRowDelete] = useState({});
  const [selectedId, setSelectedId] = useState([]);

  useEffect(() => {
    dispatch(getSegmentById(id));
    handleSearch();
  }, []);

  useEffect(() => {
    setMemberList(items);
  }, [items]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "Member No",
    },
    { field: "firstName", headerName: "ชื่อนิติบุคคล" },
    { field: "lastName", headerName: "ชื่อบุคคล" },
    { field: "nationality", headerName: "Enail" },
    { field: "phone", headerName: "Phone", renderCell: IsNullValue },
    { field: "email", headerName: "Email", renderCell: IsNullValue },
  ];

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleAddUserSegment = (row) => {
    setIsOpenAddUserSegmentModal(true);
  };

  const handleCloseAddUserSegmentPopup = () => {
    setMemberList();
    setIsOpenAddUserSegmentModal(false);
    setTimeout(() => {
      handleSearch();
    }, 500);
  };

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getMember(filter));
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getMember(initFilter));
  };

  const handleDeleteItem = ({ id }) => {
    setIsOpenconfirmDeleteUserSegmentModal(true);
    setIsDeleteUserSegment(true);
    setDataRowDelete({ id: id });
  };

  const handleCloseDeleteItemPopup = () => {
    setIsOpenconfirmDeleteUserSegmentModal(false);
    setIsDeleteUserSegment(false);
    setDataRowDelete();
    setMemberList();
    setTimeout(() => {
      handleSearch();
    }, 500);
  };

  return (
    <SidebarLayout title="Segment Management">
      {Boolean(isOpenAddUserSegment) && (
        <PopupAddUserSagment
          maxWidth={"lg"}
          open
          onClose={handleCloseAddUserSegmentPopup}
          segmentId={segmentId}
        />
      )}
      {Boolean(isOpenconfirmDeleteUserSegmentModal) && (
        <PopupConfirmDeleteUserSagment
          open
          isDelete={isDeleteUserSegment}
          dataDelete={dataRowDelete}
          onClose={handleCloseDeleteItemPopup}
          segmentId={segmentId}
        />
      )}
      <Div>
        {" "}
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper>
              <div className="segment-wrapper">
                <div className="headline">
                  <BackIcon
                    className="back-icon"
                    onClick={() => history.goBack()}
                  />
                  <span className="text-topic">
                    Segment: {segmentData?.name_th}
                  </span>
                </div>
                <Divider />
                <div className="content-wrapper">
                  <div className="section-group">
                    <div className="group-title">Search</div>
                    <div className="group-field">
                      <TextField
                        label="ชื่อ"
                        value={filter.firstName}
                        onChange={(e) =>
                          handleFilterChange("firstName", e.target.value)
                        }
                        name="search_firstname"
                      />
                      <TextField
                        label="นามสกุล"
                        value={filter.lastName}
                        onChange={(e) =>
                          handleFilterChange("lastName", e.target.value)
                        }
                        name="search_lastname"
                      />
                      <TextField
                        label="อีเมล์"
                        value={filter.email}
                        onChange={(e) =>
                          handleFilterChange("email", e.target.value)
                        }
                        name="search_email"
                      />
                    </div>
                  </div>
                  <div className="section-group">
                    <div className="group-title">User type</div>
                    <br />
                    <div className="group-field">
                      <Button
                        outlined={filter.type !== 1}
                        onClick={(e) => handleFilterChange("type", 1)}
                      >
                        Individual
                      </Button>
                      <Button
                        onClick={(e) => handleFilterChange("type", 2)}
                        outlined={filter.type !== 2}
                      >
                        Business
                      </Button>
                    </div>
                  </div>
                  <div className="section-group ">
                    <div className="group-field end">
                      <Button onClick={(e) => handleSearch()}>Search</Button>
                      <Button outlined onClick={(e) => handleClearFilter()}>
                        Clear
                      </Button>
                    </div>
                  </div>
                  <div className="section-group ">
                    <div className="group-field end">
                      <Button onClick={() => handleAddUserSegment()}>
                        Add User To Sagement
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="table-wrapper">
                  <Table
                    columns={columns}
                    rows={memberList}
                    checkboxSelection={true}
                    rowCount={total}
                    pageSize={limit}
                    page={page}
                    onSelectionChange={(id) => setSelectedId(id)}
                    onPageChange={handlePageChange}
                    onDeleteRow={handleDeleteItem}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Div>
    </SidebarLayout>
  );
};

export default MemberSegmentDetail;
