import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import TextFieldArea from "components/form/TextFieldArea";
import Button from "components/form/Button";
import Table from "components/table/Table";
import Grid from "@mui/material/Grid";
import ButtonIconDelete from "components/form/ButtonIconDelete";

const Div = styled.div`
  margin-top: 20px;
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .btn_back {
    margin-right: 20px;
  }

  .conditions-container {
    display: flex;
    padding: 10px 0px;
  }
  .input-status-topic {
    width: 200px;
    margin-right: 15px;
  }
  .input-status-condition {
    width: 120px;
    margin-right: 15px;
  }
  .input-conditionscore {
    width: 20%;
  }

  .visible {
    visibility: hidden;
  }
`;

const subjectList = [
  { value: 1, text: "maching score" },
  { value: 2, text: "disc score" },
  { value: 3, text: "percentile" },
];

const conditionList = [
  { value: "eq", text: "=" },
  { value: "gt", text: ">" },
  { value: "gte", text: ">=" },
  { value: "lt", text: "<" },
  { value: "lteq", text: "<=" },
];

const ConditionsInterviewScoreForm = ({
  indexOfForm = 0,
  formProp,
  onChange,
  handleDelete,
  type,
}) => {
  console.log(formProp);
  return (
    <Div>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <div className="conditions-container">
            <div className="input-status-topic">
              <Select
                name={"interview_condition_id"}
                items={subjectList}
                value={formProp?.interview_condition_id || formProp?.operation}
                defaultValue="เลือกเงือนไข"
                className="field-condition"
                onChange={(e) => onChange(e, indexOfForm)}
                disabled={type === "edit" ? true : false}
              />
            </div>
            <div className="input-status-condition">
              <Select
                name={"operation"}
                items={conditionList}
                value={formProp?.operation}
                defaultValue="Operation"
                onChange={(e) => onChange(e, indexOfForm)}
                className="field-condition"
              />
            </div>
            <div className="input-conditionscore">
              <TextField
                name="score"
                type="number"
                value={formProp?.score}
                onChange={(e) => onChange(e, indexOfForm)}
                className="field_conditionscore"
              />
            </div>
            {indexOfForm !== 0 && (
              <ButtonIconDelete
                className={type === "edit" ? "visible" : ""}
                onClick={() => handleDelete(indexOfForm)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Div>
  );
};

export default ConditionsInterviewScoreForm;
