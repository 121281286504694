import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import Date from "components/form/Date";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import FileCell from "components/table/FileCell";
import StatusCell from "components/table/StatusCell";
import CheckCell from "components/table/CheckCell";
import PopupAddBusinessMember from "containers/popup/PopupAddBusinessMember";
import PopupAddIndividualMember from "containers/popup/PopupAddIndividualMember";
import PopupApproveDocument from "containers/popup/PopupApproveDocument";
import PopupChangeStatusMember from "../../containers/popup/PopupChangeStatusMember";

import {} from "redux/selectors";
import {
  getMember,
  getMemberById,
  resendEmail,
  forgetPassword,
} from "redux/actions/member";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  idCard: "",
  firstName: "",
  lastName: "",
  email: "",
  type: 1,
};

const MemberAll = () => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.member);

  const [memberList, setMemberList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [selectedMemberId, setSelectedMemberId] = useState([]);

  const [isOpenAddIndividualModal, setIsOpenAddIndividualModal] =
    useState(false);
  const [isOpenAddBusinessModal, setIsOpenAddBusinessModal] = useState(false);
  const [isOpenApproveDocumentModal, setIsOpenApproveDocumentModal] =
    useState(false);
  const [isOpenPopupStatus, setIsOpenPopupStatus] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setMemberList(items);
  }, [items]);

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/member/detail/${row?.id}`}>{value}</RouterLinkCell>
    );
  };

  const StatusCellWrapper = ({ value }) => {
    let type = "";
    if (value === 1) {
      type = "Individual";
    } else if (value === 2) {
      type = "Business";
    }
    return <StatusCell type={type}>{type}</StatusCell>;
  };

  const StatusCellApproveStatus = ({ value }) => {
    let type = "";
    if (value === null || !value) return "-";
    return <StatusCell type={value}>{value}</StatusCell>;
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ApproveDocument = ({ value }) => {
    return (
      <Button onClick={() => handleApproveDocument(value)}>
        Approve Document
      </Button>
    );
  };

  const ActionResendEmailCell = ({ row }) => {
    return (
      <Button onClick={() => handleResendEmail(row?.id)}>Resend Email</Button>
    );
  };
  const ActionForgetPasswordCell = ({ row }) => {
    return (
      <Button onClick={() => handleForgetPassword(row?.id)}>
        Forget Password
      </Button>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "",
      renderCell: ApproveDocument,
      width: 200,
    },
    {
      field: "idCard",
      headerName: "Id Card Number",
      renderCell: LinkDetailCell,
      width: 200,
    },
    { field: "firstName", headerName: "Name", width: 200 },
    { field: "lastName", headerName: "Surname", width: 200 },
    { field: "nationality", headerName: "Nationality", width: 200 },
    {
      field: "phone",
      headerName: "Phone",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      renderCell: IsNullValue,
      width: 200,
    },
    { field: "bankAccountNo", headerName: "Bank Account Number", width: 200 },
    { field: "bankAccountName", headerName: "Bank Account Name", width: 200 },
    {
      field: "memberType",
      headerName: "Member Type",
      renderCell: StatusCellWrapper,
      width: 200,
    },
    {
      field: "approveStatusFormatted",
      headerName: "Approve Status",
      renderCell: StatusCellApproveStatus,
      width: 200,
    },
    {
      field: "-",
      headerName: "Action",
      renderCell: ActionResendEmailCell,
      width: 200,
    },
    {
      field: "",
      headerName: "Action Forget",
      renderCell: ActionForgetPasswordCell,
      width: 200,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getMember(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getMember(filter));
  };

  const handleCloseIndividualPopup = () => {
    setIsOpenAddIndividualModal(false);
    handleSearch();
  };

  const handleCloseBusinessPopup = () => {
    setIsOpenAddBusinessModal(false);
    handleSearch();
  };

  const handleCloseApprovePopup = () => {
    setIsOpenApproveDocumentModal(false);
    setTimeout(() => {
      handleSearch();
    }, 2000);
  };

  const handleAddIndividualMember = () => {
    setIsOpenAddIndividualModal(true);
  };

  const handleAddBusinessMember = () => {
    setIsOpenAddBusinessModal(true);
  };

  const handleApproveDocument = (id) => {
    setIsOpenApproveDocumentModal(true);
    dispatch(getMemberById(id));
  };

  const handleOpenPopupStatus = () => {
    setIsOpenPopupStatus(true);
  };

  const handleClosePopupStatus = () => {
    setIsOpenPopupStatus(false);
    window.location.reload();
  };

  const handleEditMember = (dataRow) => {};

  const handleResendEmail = (memberId) => {
    dispatch(resendEmail(memberId));
  };
  const handleForgetPassword = (memberId) => {
    dispatch(forgetPassword(memberId));
  };

  const handleSelectMember = (idList) => {
    let newArr = [];
    for (const id of idList) {
      let item = memberList.find((it) => it.id === id);
      newArr.push({
        id: item?.id,
        name: `${item?.firstName} ${item?.lastName}`,
        status: item?.memberStatus,
      });
    }

    setSelectedMemberId(newArr);
  };

  return (
    <SidebarLayout title="Member Management">
      {Boolean(isOpenAddBusinessModal) && (
        <PopupAddBusinessMember open onClose={handleCloseBusinessPopup} />
      )}
      {Boolean(isOpenAddIndividualModal) && (
        <PopupAddIndividualMember open onClose={handleCloseIndividualPopup} />
      )}
      {Boolean(isOpenApproveDocumentModal) && (
        <PopupApproveDocument
          maxWidth={"lg"}
          open
          onClose={handleCloseApprovePopup}
        />
      )}
      {isOpenPopupStatus && (
        <PopupChangeStatusMember
          open
          onClose={handleClosePopupStatus}
          selectList={selectedMemberId}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/member/all", text: "All" },
            { path: "/member/segment", text: "Segment" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="ชื่อ"
                  value={filter.firstName}
                  onChange={(e) =>
                    handleFilterChange("firstName", e.target.value)
                  }
                  name="search_firstname"
                />
                <TextField
                  label="นามสกุล"
                  value={filter.lastName}
                  onChange={(e) =>
                    handleFilterChange("lastName", e.target.value)
                  }
                  name="search_lastname"
                />
                <TextField
                  label="อีเมล์"
                  value={filter.email}
                  onChange={(e) => handleFilterChange("email", e.target.value)}
                  name="search_email"
                />
              </div>
            </div>
            <div className="section-group">
              <div className="group-title">User type</div>
              <br />
              <div className="group-field">
                <Button
                  outlined={filter.type !== 1}
                  onClick={(e) => handleFilterChange("type", 1)}
                >
                  Individual
                </Button>
                <Button
                  onClick={(e) => handleFilterChange("type", 2)}
                  outlined={filter.type !== 2}
                >
                  Business
                </Button>
              </div>
            </div>
            <br />
            {/* <div className="section-group">
              <div className="group-field">
                <Select
                  label="Report Status"
                  items={statusList}
                  value={filter.status}
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                />
              </div>
            </div> */}
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button
                    disabled={selectedMemberId.length === 0}
                    onClick={handleOpenPopupStatus}
                  >
                    change stutus member
                  </Button>
                  <Button
                    id="btn-add-member-individual"
                    onClick={handleAddIndividualMember}
                  >
                    Add Individual Member
                  </Button>
                  <Button
                    id="btn-add-member-business"
                    onClick={handleAddBusinessMember}
                  >
                    Add Business Member
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={memberList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              onSelectionChange={handleSelectMember}
              disableSelectionOnClick={true}
              // onEditRow={handleEditMember}
              // onDeleteRow={handleDeleteMember}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default MemberAll;
