import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import { Grid, Tabs, Tab, Box } from "@material-ui/core";
import InlineSpaceText from "components/form/InlineSpaceText";
import Paper from "components/surfaces/Paper";
import Table from "components/table/Table";
import Divider from "components/surfaces/Divider";
import Button from "components/form/Button";
import { ArrowBack as BackIcon } from "@material-ui/icons";
import { Edit as EditIcon } from "@material-ui/icons";
import TabPanel from "components/surfaces/TabPanel";
import TabDNAStatus from "./TabDNAStatus";
import PopupEditInformationMember from "../../containers/popup/PopupEditInformationMember";
import PopupEditInformationPersonal from "../../containers/popup/PopupEditInformationPersonal";
import PopupChangeStatusMember from "../../containers/popup/PopupChangeStatusMember";

import { getMemberById } from "../../redux/actions/member";

import { checkValue, GetAddressTH } from "../../utils/common";
import {
  selectMasterDataProvince,
  selectMasterDataDistrict,
  selectMasterDataSubDistrict,
  selectMasterDataBank,
} from "../../redux/selectors";

const Div = styled.div`
  padding: 20px;

  .headbar-panel-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .back-icon {
      font-size: 35px;
      :hover {
        cursor: pointer;
      }
    }
    .text-topic {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .user-profile-wrapper {
    text-align: center;
    padding: 25px 12px;

    .image-profile {
      width: 140px;
      height: 140px;
      margin: auto;
      background: #e8ecef;
      border-radius: 50%;
    }

    .name-profile {
      margin-top: 33px;
      font-weight: bold;
    }
  }
  .user-infos-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .headline {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 10px;
      height: 35px;
    }
    .content-wrapper {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      row-gap: 0.5rem;
    }
  }
  .table-wrapper {
    .table-header {
      margin-bottom: 26px;

      .label {
        font-weight: bold;
        width: 200px;
      }

      button {
        margin-top: 20px;
      }
    }
  }
  .issue-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 38px;
    border-radius: 4px;
    background: #e44c5c;
    color: #fff;
  }
  .date-issue-wrapper {
    color: #e44c5c;
  }

  .end {
    float: right;
  }
`;

const Memberdetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const masterDataBank = useSelector(selectMasterDataBank);
  const masterDataProvince = useSelector(selectMasterDataProvince);
  const masterDataDistrict = useSelector(selectMasterDataDistrict);
  const masterDataSubDistrict = useSelector(selectMasterDataSubDistrict);

  const { member_id } = useParams();
  const { memberData } = useSelector((state) => state.member);
  const profileImage = memberData?.verify_document?.[0]?.document_url;
  const taxAddressId = memberData?.member_address?.[0]?.id;
  const DocAddressId = memberData?.member_address?.[1]?.id;

  const [isOpenPopupMember, setIsOpenPopupMember] = useState(false);
  const [isOpenPopupPersonal, setIsOpenPopupPersonal] = useState(false);
  const [isOpenPopupStatus, setIsOpenPopupStatus] = useState(false);

  // const [tabActive, setTabActive] = useState(0);
  // const [page, setPage] = useState(0);
  // const [selectedId, setSelectedId] = useState([]);

  useEffect(() => {
    dispatch(getMemberById(member_id));
  }, [member_id]);

  // const handleChangeTab = (event, newValue) => {
  //   setTabActive(newValue);
  // };

  // const handlePageChange = (nextPage) => {
  //   setPage(nextPage);
  // };

  const handleDeleteItem = ({ id }) => {};

  const columnsHistory = [
    { field: "id", headerName: "Order ID" },
    { field: "detail", headerName: "Order Details" },
    { field: "date", headerName: "Order Date" },
    { field: "status", headerName: "Order Status" },
  ];

  const GetBankTH = (data) => {
    const bankName = masterDataBank.find(
      (it) => it?.value === memberData?.bank_account
    )?.text;
    return bankName;
  };

  const GetAddressTH = (data) => {
    const {
      address,
      road,
      province_id,
      district_id,
      sub_district_id,
      zip_code,
    } = data;

    const provinceName = masterDataProvince
      ?.map((it) => it)
      .find((it) => it?.value === province_id)?.text;
    const districtName = masterDataDistrict
      ?.map((it) => it)
      .find((it) => it?.value === district_id)?.text;
    const subDistrictName = masterDataSubDistrict
      ?.map((it) => it)
      .find((it) => it?.value === sub_district_id)?.text;

    return `${address} ${road} ${provinceName} ${districtName} ${subDistrictName} ${zip_code}`;
  };

  const handleOpenPopupMember = () => {
    setIsOpenPopupMember(true);
  };
  const handleClosePopupMember = () => {
    setIsOpenPopupMember(false);
    setTimeout(() => {
      dispatch(getMemberById(member_id));
    }, 1000);
  };
  const handleOpenPopupPersonal = () => {
    setIsOpenPopupPersonal(true);
  };
  const handleClosePopupPersonal = () => {
    setIsOpenPopupPersonal(false);
    setTimeout(() => {
      dispatch(getMemberById(member_id));
    }, 1000);
  };
  const handleOpenPopupStatus = () => {
    setIsOpenPopupStatus(true);
  };
  const handleClosePopupStatus = () => {
    setIsOpenPopupStatus(false);
    setTimeout(() => {
      dispatch(getMemberById(member_id));
    }, 1000);
  };

  return (
    <SidebarLayout title="Member Management">
      <Div>
        {isOpenPopupMember && (
          <PopupEditInformationMember
            open
            onClose={handleClosePopupMember}
            memberType={memberData?.member_type}
          />
        )}
        {isOpenPopupPersonal && (
          <PopupEditInformationPersonal
            open
            onClose={handleClosePopupPersonal}
            memberType={memberData?.member_type}
            taxAddressId={taxAddressId}
            DocAddressId={DocAddressId}
          />
        )}
        {isOpenPopupStatus && (
          <PopupChangeStatusMember
            open
            onClose={handleClosePopupStatus}
            selectList={[
              {
                id: member_id,
                name: `${memberData?.first_name} ${memberData?.last_name}`,
                status: memberData?.member_status,
              },
            ]}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} className="headbar-panel-wrapper">
            <BackIcon className="back-icon" onClick={() => history.goBack()} />
            <span className="text-topic">ผู้ใช้งาน</span>
          </Grid>
          <Grid item xs={3}>
            <Paper>
              <div className="user-profile-wrapper">
                <img
                  className="image-profile"
                  src={profileImage}
                  alt="profile"
                />
                <div className="name-profile">
                  {checkValue(memberData?.first_name)}{" "}
                  {checkValue(memberData?.last_name)}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper>
              <div className="user-infos-wrapper ">
                <div className="headline ">
                  Member Information
                  <EditIcon
                    className="end clickable"
                    onClick={() => handleOpenPopupMember()}
                  />
                  <Divider />
                </div>
                <div className="content-wrapper">
                  <InlineSpaceText label="อีเมล ">
                    <span>{checkValue(memberData?.email)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="เบอร์โทร ">
                    <span>{checkValue(memberData?.phone)}</span>
                  </InlineSpaceText>

                  <InlineSpaceText label="วัน เดือน ปี ที่จดทะเบียน ">
                    <span>-</span>
                  </InlineSpaceText>

                  <InlineSpaceText label="ประเภทลูกค้า ">
                    <span>
                      {memberData?.member_type === 1
                        ? "Individual"
                        : "Business"}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText label="segment ">
                    <span>{checkValue(memberData?.segment)}</span>
                  </InlineSpaceText>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <div className="user-infos-wrapper ">
                <div className="headline">
                  Member Status
                  <EditIcon
                    className="end clickable"
                    onClick={() => handleOpenPopupStatus()}
                  />
                  <Divider />
                </div>
                <div className="content-wrapper">
                  <InlineSpaceText label="สถานะผู้ใช่ ">
                    <span>
                      {checkValue(memberData?.member_status_formatted)}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText label="สถานะเอกสาร ">
                    <span>
                      {checkValue(memberData?.approve_status_formatted)}
                    </span>
                  </InlineSpaceText>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <div className="user-infos-wrapper ">
                <div className="headline">
                  Personal Information
                  <EditIcon
                    className="end clickable"
                    onClick={() => handleOpenPopupPersonal()}
                  />
                  <Divider />
                </div>

                <div className="content-wrapper">
                  <InlineSpaceText label="สัญชาติ">
                    <span>{checkValue(memberData?.nationality)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="เลขบัตรประจำตัวประชาชน ">
                    <span>{checkValue(memberData?.id_card)}</span>
                  </InlineSpaceText>
                  {(memberData?.member_address || []).map((item, index) => (
                    <InlineSpaceText
                      label={
                        item?.address_type === 1
                          ? "ที่อยู๋ปัจจุบัน/ออกบิลใบเสร็จ/ใบกำกับ"
                          : "ที่อยู่สำหรับจัดส่งเอกสาร"
                      }
                    >
                      {checkValue(GetAddressTH(item))}
                    </InlineSpaceText>
                  ))}

                  {/* <InlineSpaceText label="ที่อยู่สำหรับจัดส่งเอกสาร ">
                    <span>
                      {checkValue(
                        GetAddressTH(member_address && member_address[1])
                      )}
                    </span>
                  </InlineSpaceText> */}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <div className="user-infos-wrapper ">
                <div className="headline">
                  Extra Information
                  <EditIcon
                    className="end clickable"
                    onClick={() => handleOpenPopupMember()}
                  />
                  <Divider />
                </div>
                <div className="content-wrapper">
                  <InlineSpaceText label="ธนาคาร">
                    <span>{checkValue(GetBankTH())}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="เลขบัญชีธนาคาร">
                    <span>{checkValue(memberData?.bank_account_no)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText label="ชื่อบัญชี">
                    <span>{checkValue(memberData?.bank_account_name)}</span>
                  </InlineSpaceText>
                </div>
              </div>
            </Paper>
          </Grid>

          {/* <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabActive}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Order History" />
                <Tab label="DNA Report" />
                <Tab label="DNA Status" />
                <Tab label="Quiz" />
              </Tabs>
            </Box>
            <TabPanel value={tabActive} index={0}>
              <div className="table-wrapper">
                <div className="table-header">
                  <div className="label">Order History</div>
                </div>
                <Table
                  columns={columnsHistory}
                  rows={items}
                  rowCount={total}
                  pageSize={limit}
                  page={page}
                  checkboxSelection={false}
                  onPageChange={handlePageChange}
                  onDeleteRow={handleDeleteItem}
                />
              </div>
            </TabPanel>
            <TabPanel value={tabActive} index={1}>
              DNA Report
            </TabPanel>
            <TabPanel value={tabActive} index={2}>
              <TabDNAStatus />
            </TabPanel>
            <TabPanel value={tabActive} index={3}>
              Quiz
            </TabPanel>
          </Box> */}
        </Grid>
      </Div>
    </SidebarLayout>
  );
};

export default Memberdetail;
