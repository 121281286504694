import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";

import Tooltip from "@mui/material/Tooltip";

import PopupApproveExamQuestion from "containers/popup/PopupApproveExamQuestion";

import {} from "redux/selectors";
import { getExamSetList } from "redux/actions/library";
import PopupCreateExamSet from "containers/popup/PopupCreateExamSet";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  examId: "",
  proposition: "",
  seterName: null,
};

const originateList = [{ value: null, text: "Originate" }];

const ExamSeriesAll = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, total, limit } = useSelector((state) => state.library);

  const [examUserList, setExamUserList] = useState([]);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [isOpenCreateExamModal, setIsOpenCreateExamModal] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setExamUserList(items);
  }, [items]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const DisplayName = ({ value }) => {
    return (
      <Tooltip title={`${value}`} arrow>
        <div>{value}</div>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับ",
      width: 200,
    },
    {
      field: "code",
      headerName: "รหัสชุดข้อสอบ",
      width: 200,
    },
    {
      field: "name",
      headerName: "ชื่อชุดข้อสอบ",
      width: 400,
      renderCell: DisplayName,
    },
    {
      field: "amountQuestion",
      headerName: "จำนวนข้อสอบ",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "originateBy",
      headerName: "Originate By",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "author",
      headerName: "author",
      width: 200,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getExamSetList(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getExamSetList(filter, nextPage * limit));
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getExamSetList(filter));
  };

  const handleCreateExam = () => {
    history.push("/library/examseries/create");
  };

  const handleCloseCreateExam = () => {
    setIsOpenCreateExamModal(false);
  };

  const handleCloseApproveExamQuestion = () => {
    setIsOpenApproveModal(false);
  };

  const handleEditExamSet = (row) => {
    history.push(`/library/examseries/edit/${row?.id}`);
  };

  return (
    <SidebarLayout title="คลังข้อสอบผู้ตรวจสอบอาคารสภาวิศวกร">
      {Boolean(isOpenCreateExamModal) && (
        <PopupCreateExamSet open onClose={handleCloseCreateExam} />
      )}
      {Boolean(isOpenApproveModal) && (
        <PopupApproveExamQuestion
          open
          onClose={handleCloseApproveExamQuestion}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="รหัสข้อสอบ"
                  value={filter.examId}
                  onChange={(e) => handleFilterChange("examId", e.target.value)}
                  name="search_examid"
                />
                <TextField
                  label="ชุดข้อสอบ"
                  value={filter.examserie}
                  onChange={(e) =>
                    handleFilterChange("examserie", e.target.value)
                  }
                  name="search_examserien"
                />
                <TextField
                  label="บริษัท"
                  value={filter.company}
                  onChange={(e) =>
                    handleFilterChange("company", e.target.value)
                  }
                  name="search_company"
                />
                <TextField
                  label="Author"
                  value={filter.author}
                  onChange={(e) => handleFilterChange("author", e.target.value)}
                  name="search_author"
                />
              </div>
              <div className="group-field">
                <Select
                  name={"originate"}
                  items={originateList}
                  value={""}
                  defaultValue="Originate"
                  className="field-originate"
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button
                    color={"secondary"}
                    onClick={() => handleCreateExam()}
                  >
                    สร้างชุดข้อสอบ
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={examUserList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              onEditRow={handleEditExamSet}
              
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExamSeriesAll;
