import React, { useState, useEffect } from "react";
import styled from "styled-components";

import TextField from "components/form/TextField";
import TextFieldArea from "components/form/TextFieldArea";
import Select from "components/form/Select";
import { Grid } from "@material-ui/core";
import { SUBJECT_DETAIL_CONFIG_REPORT } from "utils/dataConfig";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0;

  .group-title {
    font-size: 20px;
    font-weight: bold;
  }
`;

const SubjectFixPanel = ({
  formProp,
  subjectName,
  onChange,
}) => {
  useEffect(() => {
    switch (subjectName) {
      case "recommendation":
        onChange(SUBJECT_DETAIL_CONFIG_REPORT?.recommendation);
        break;
      case "percentile":
        onChange(SUBJECT_DETAIL_CONFIG_REPORT?.percentile);
        break;
      case "matching_score":
        onChange(SUBJECT_DETAIL_CONFIG_REPORT?.matching_score);
        break;

      default:
        break;
    }
  }, []);

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <br />
        </Grid>

        <Grid container spacing={1}>
          {formProp?.map((item, index) => (
            <>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  value={item?.name}
                  onChange={onChange}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Grid spacing={1} container direction="row" alignItems="center">
                  <Grid item xs={5}>
                    <TextField
                      disabled
                      name={"min_score"}
                      value={item?.min_score}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid container item justifyContent="center" xs={2}>
                    To
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      disabled
                      name={"max_score"}
                      value={item?.max_score}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <TextFieldArea
                  name={"description"}
                  onChange={onChange}
                  value={item?.description}
                  disabled
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SubjectFixPanel;
