import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import SlideAlert from "containers/SlideAlert";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Home from "pages/Home";

import MemberWelcome from "pages/member/MemberWelcome";
import MemberAll from "pages/member/MemberAll";
import MemberSagment from "pages/member/MemberSagment";
import MemberSegmentDetail from "pages/member/MemberSegmentDetail";
import MemberDetail from "pages/member/MemberDetail";

import OrderWelcome from "pages/order/OrderWelcome";
import OrderTransaction from "pages/order/OrderTransaction";
import OrderTransactionCreateQuotation from "pages/order/OrderTransactionCreateQuotation";
import OrderTransactionGenerateInvoice from "pages/order/OrderTransactionGenerateInvoice";
import OrderTransactionGenerateReceipt from "pages/order/OrderTransactionGenerateReceipt";
import OrderTransactionGenerateDeliveryNote from "pages/order/OrderTransactionGenerateDeliveryNote";
import OrderRegconsign from "pages/order/OrderRegconsign";
import OrderSummaryReport from "pages/order/OrderSummaryReport";

import WrapperQuotation from "containers/documents/WrapperQuotation";
import WrapperInvoice from "containers/documents/WrapperInvoice";
import WrapperReceipt from "containers/documents/WrapperReceipt";
import WrapperDelivery from "containers/documents/WrapperDelivery";

import ReportWelcome from "pages/report/ReportWelcome";
import ReportAll from "pages/report/ReportAll";

import CampaignWelcome from "pages/campaign/CampaignWelcome";
import CampaignAll from "pages/campaign/CampaignAll";

import ConfigurationReportWelcome from "pages/configurationReport/ConfigurationReportWelcome";
import ConfigurationReportAll from "pages/configurationReport/ConfigurationReportAll";
import ConfigurationReportCreate from "pages/configurationReport/ConfigurationReportCreate";
import ConfigurationDISC from "pages/configurationReport/ConfigurationDISC";

import ExaminerDetail from "pages/centerLibrary/ExaminerDetail";
import ExaminationAll from "pages/centerLibrary/ExaminationAll";
import ExamSeriesAll from "pages/centerLibrary/ExamSeriesAll";
import ExamSeriesCreate from "pages/centerLibrary/ExamSeriesCreate";
import ExaminationDetail from "pages/centerLibrary/ExaminationDetail";

import SecureRoute from "./SecureRoute";
import {
  getMasterDataProvince,
  getMasterDataDistrict,
  getMasterDataSubDistrict,
  getMasterDataBank,
  getMasterDataNationality,
  getMasterDataSubject,
  getMasterDataJobs,
} from "redux/actions/masterData";
import LibaryWelcome from "pages/centerLibrary/LibaryWelcome";
import ExaminerAll from "pages/centerLibrary/ExaminerAll";

const Router = () => {
  const accessToken = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  useEffect(() => {
    if (accessToken) {
      dispatch(getMasterDataProvince());
      dispatch(getMasterDataDistrict());
      dispatch(getMasterDataSubDistrict());
      dispatch(getMasterDataBank());
      dispatch(getMasterDataNationality());
      dispatch(getMasterDataSubject());
      dispatch(getMasterDataJobs());
    }
  }, [accessToken, dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Logout} exact />
        <SecureRoute path="/" component={LibaryWelcome} exact />
        <SecureRoute path="/library" component={LibaryWelcome} exact />
       
        <SecureRoute
          path="/library/examiner/all"
          component={ExaminerAll}
          exact
        />
        <SecureRoute
          path="/library/exminer/detail/:id"
          component={ExaminerDetail}
          exact
        />
        <SecureRoute
          path="/library/examination/all"
          component={ExaminationAll}
          exact
        />
        <SecureRoute
          path="/library/examination/detail/:id"
          component={ExaminationDetail}
          exact
        />
        <SecureRoute
          path="/library/examseries/all"
          component={ExamSeriesAll}
          exact
        />
        <SecureRoute
          path="/library/examseries/:type"
          component={ExamSeriesCreate}
          exact
        />
        <SecureRoute
          path="/library/examseries/:type/:id"
          component={ExamSeriesCreate}
          exact
        />
      </Switch>
      <SlideAlert />
    </BrowserRouter>
  );
};

export default Router;
