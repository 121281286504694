import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import StatusCell from "components/table/StatusCell";

import PopupBatchUpdate from "../../containers/popup/PopupBatchUpdate";
import PopupBatchCampaign from "../../containers/popup/PopupBatchReport";
import PopupGetAPI from "../../containers/popup/PopupGetAPI";
import PopupSelectReportType from "../../containers/popup/PopupSelectReportType";

import moment from "moment";

import {
  getCampaigns,
  getReportConfigsDropdown,
  updateCampaignStatus,
} from "redux/actions/report";
import Date from "components/form/Date";
import PopupSelectReportTypeCSV from "containers/popup/PopupSelectReportTypeCSV";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  name: "",
  companyName: "",
  startDate: null,
  endDate: null,
  status: "",
  campaignType: "",
};

const statusList = [
  { text: "Active", value: "active" },
  { text: "Inactive", value: "inactive" },
];

const campaignTypeList = [
  { text: "Evaluate", value: "evaluate" },
  { text: "Recruitment", value: "recruitment" },
];

const CampaignAll = () => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.report);

  const [campaignList, setCampaignList] = useState([]);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const [campaignId, setCampaignId] = useState(null);

  const [isOpenPopupBatchUpdate, setIsOpenPopupBatchUpdate] = useState(false);
  const [isOpenPopupGetAPI, setIsOpenPopupGetAPI] = useState(false);
  const [isOpenPopupCampaignGenerate, setIsOpenPopupCampaignGenerate] =
    useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    let index = 1;
    let newItems = items.map((it) => {
      return { ...it, id: index++ };
    });
    setCampaignList(newItems);
  }, [items]);

  const handleSearch = () => {
    setPage(0);
    dispatch(getCampaigns(filter));
  };

  useEffect(() => {}, []);

  const GetAPIButton = ({ row }) => {
    return <Button onClick={() => handleGetAPI(row)}>Button Get API</Button>;
  };

  const GenerateCampaignButton = ({ row }) => {
    return (
      <Button onClick={() => handleCampaignGenerate(row)}>
        Button Generate Report
      </Button>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 250,
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      width: 300,
    },
    {
      field: "companyName",
      headerName: "ชื่อบริษัท",
      width: 250,
      renderCell: IsNullValue,
    },
    { field: "campaignStartDate", headerName: "วันที่เริ่ม", width: 250 },
    { field: "campaignEndDate", headerName: "วันที่จบ", width: 250 },
    {
      field: "userExam",
      headerName: "จำนวนผู้เข้าสอบ",
      width: 250,
    },
    {
      field: "campaignStatus",
      headerName: "Status",
      width: 250,
    },
    {
      field: "getApi",
      headerName: "Get API",
      width: 250,
      renderCell: GetAPIButton,
    },
    {
      field: "-",
      headerName: "Report",
      width: 250,
      renderCell: GenerateCampaignButton,
    },
  ];

  const handleBatchUpdate = () => {
    setIsOpenPopupBatchUpdate(true);
  };

  const handleCloseBatchUpdate = () => {
    setIsOpenPopupBatchUpdate(false);
    handleSearch();
  };

  const handleGetAPI = (row) => {
    dispatch(updateCampaignStatus(row?.id)).then((data) =>
      setIsOpenPopupGetAPI(true)
    );
  };

  const handleCloseGetAPI = () => {
    setIsOpenPopupGetAPI(false);
    handleSearch();
  };

  const handleCampaignGenerate = (row) => {
    setCampaignId(row?.id);
    dispatch(getReportConfigsDropdown()).then(() =>
      setIsOpenPopupCampaignGenerate(true)
    );
  };

  const handleCloseCampaignGenerate = () => {
    setIsOpenPopupCampaignGenerate(false);
  };

  const handleFilterChange = (key, value) => {
    console.log(value);
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getCampaigns(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getCampaigns(filter, nextPage * limit));
  };

  return (
    <SidebarLayout title="Campaign Management">
      {Boolean(isOpenPopupBatchUpdate) && (
        <PopupBatchUpdate open onClose={handleCloseBatchUpdate} />
      )}
      {Boolean(isOpenPopupGetAPI) && (
        <PopupGetAPI open onClose={handleCloseGetAPI} />
      )}
      {Boolean(isOpenPopupCampaignGenerate) && (
        <PopupSelectReportTypeCSV
          open
          onClose={handleCloseCampaignGenerate}
          campaignId={campaignId}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/report/all", text: "Report" },
            { path: "/campaign/all", text: "Campaign" },
            { path: "/configuration-report/all", text: "Configuration Report" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="Campaign Name"
                  value={filter.name}
                  name="search_name"
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                />
                <TextField
                  label="ชื่อบริษัท"
                  value={filter.companyName}
                  name="search_companyname"
                  onChange={(e) =>
                    handleFilterChange("companyName", e.target.value)
                  }
                />
                <Date
                  placeholder="วันที่เริ่มต้น"
                  value={filter.startDate}
                  disableFuture
                  onChange={(date) =>
                    handleFilterChange(
                      "startDate",
                      moment(date).format("YYYY-MM-DD")
                    )
                  }
                />
                <Date
                  placeholder="วันที่จบ"
                  value={filter.endDate}
                  disableFuture
                  onChange={(date) =>
                    handleFilterChange(
                      "endDate",
                      moment(date).format("YYYY-MM-DD")
                    )
                  }
                />
              </div>
              <div className="group-field">
                <Select
                  items={statusList}
                  value={filter.status}
                  defaultValue="Status"
                  className="field-status"
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                />
                <Select
                  items={campaignTypeList}
                  value={filter.campaignType}
                  defaultValue="ประเภท Campaign"
                  className="field-campaign"
                  onChange={(e) =>
                    handleFilterChange("campaignType", e.target.value)
                  }
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={handleBatchUpdate}>Batch Update</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={campaignList}
              rowCount={total}
              pageSize={+limit}
              page={page}
              disableSelectionOnClick={true}
              checkboxSelection={false}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default CampaignAll;
