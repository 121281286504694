import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import Button from "components/form/Button";

import Grid from "@mui/material/Grid";

import Table from "components/table/Table";
import StatusCell from "components/table/StatusCell";

import { getProducts } from "redux/actions/order";

const initFilter = {
  productName: "",
};

const Div = styled.div`
  padding: 20px;
  width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    padding-left: 250px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }
`;

const PopupProductQuotation = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth,
  error,
  segmentId,
  setProducts = [],
  products,
}) => {
  const dispatch = useDispatch();
  const { items, total, limit } = useSelector((state) => state.order);
  const [productList, setProductList] = useState(items);
  const [productArr, setProductArr] = useState([]);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [priceList, setPriceList] = useState([]);

  const IsPriceNullValue = ({ value, row }) => {
    if (value === 0)
      return (
        <TextField
          name="field-price"
          type="number"
          onChange={(e) => handlePriceChange(row?.id, e.target.value)}
        />
      );
    return value;
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
    },
    { field: "productCode", headerName: "รหัสสินค้า" },
    { field: "productName", headerName: "Product Name" },
    { field: "productDetailTh", headerName: "Detail" },
    { field: "total", headerName: "Price", renderCell: IsPriceNullValue },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  const handlePriceChange = (id, value) => {
    let priceArr = [...priceList];
    let isIdExist = priceArr.some((it) => it.index === id);
    if (!isIdExist) {
      priceArr.push({ id: id, price: value });
    } else {
      let indexArr = priceArr.findIndex((it) => it.id === id);
      priceArr[indexArr].price = +value;
    }
    setPriceList(priceArr);
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getProducts(filter));
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getProducts(initFilter));
  };

  const submitAddProduct = () => {
    let newArr = [...productArr];
    console.log(newArr);
    for (const item of priceList) {
      let index = newArr.findIndex((it) => it.id === item.id);
      newArr[index].total = item.price;
    }
    setProducts(newArr);
    onClose();

    // setProducts(productArr);
    // onClose();
  };

  const selectProduct = (idList) => {
    if (idList.length === 0) return;
    let newArr = [...products];
    for (const id of idList) {
      let isProductExist = newArr.some((it) => it.id === id);
      if (!isProductExist) {
        let item = productList.find((it) => it.id === id);
        newArr.push(item);
      }
    }
    setProductArr(newArr);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setProductList(items);
  }, [items]);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Add User To Segment</div>
          </Grid>
          <Grid item xs={2}>
            <Button
              className="submit_add_product"
              type="submit"
              onClick={(e) => submitAddProduct()}
            >
              Add Product
            </Button>
          </Grid>
        </Grid>

        <div className="section-group">
          <div className="group-title">Product Information</div>
          <div className="group-field">
            <TextField
              label="Product Name"
              value={filter.lastName}
              onChange={(e) =>
                handleFilterChange("productName", e.target.value)
              }
              name="search_productName"
            />
          </div>
        </div>
        <div className="section-group ">
          <div className="group-field end">
            <Button onClick={() => handleSearch()}>Search</Button>
            <Button onClick={() => handleClearFilter()} outlined>
              Clear
            </Button>
          </div>
        </div>
        <div className="table-wrapper">
          <Table
            columns={columns}
            rows={productList}
            checkboxSelection={true}
            rowCount={total}
            pageSize={limit}
            page={page}
            onPageChange={handlePageChange}
            onSelectionChange={(id) => selectProduct(id)}
          />
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          {/* <ButtonBlack type="submit" onClick={() => submitAddSegment()}>
            Add
          </ButtonBlack> */}
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupProductQuotation;
